#root {
  width: 100vw;
  overflow: hidden;
}
$white-color: #ffffff;
$black-color: #000000;
$theme-background:#76272B;
$water-color:#0464FC;
$gray-color: #c4c4c4;

$font-11: 0.688rem;
$font-12: 0.75rem;
$font-13: 0.813rem;
$font-14: 0.875rem;
$font-15: 0.938rem;
$font-16: 1rem;
$font-18: 1.125rem;
$font-22: 1.375rem;
$font-24: 1.5rem;
$font-30: 1.875rem;
$w-600: 600;

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-ms-reveal, input::-ms-clear {
  display: none;
}

html, body{
  min-height: 100% !important;
  height: 100%;
  font-size: 16px;
}
body{
  padding: 0;
  margin: 0;
}
#mainBody {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  padding: 16px;
  gap: 20px;
  overflow: hidden;

  > div {
    // aimed to target both right & left
    border-radius: 8px;
    overflow: hidden;
  }

  // > .right {
  //   flex: 6;
  // }
  
  > .right {
    width: 55%;;
  }

  // > .left {
  //   flex: 5;
  // }
  > .left {
    width: 45%;
  }
}
.theme-btn {
  min-height: 2.375rem;
  height: 100%;
  min-width: 5.313rem;
  padding: 0.438rem 0.813rem;
  background-color: $theme-background;
  color: #ffffff;
  border-radius: 2px;
  font-size: 1.250rem;
  font-weight: 600;
  box-shadow: none;
  border: 1px solid $theme-background;
}

.feedbackMain {
  display: flex;
  flex-direction: column;

  width: 100% !important;
  height: 100%;
  padding: 30px;

  // background css
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  background-size: cover;

  &.imageLogin {
    background-image: url("../../public/images/mainBodyRightSideImages/imageFscreen.png");
  }

  &.imageSignUp {
    background-image: url("../../public/images/mainBodyRightSideImages/imageFscreen.png");
  }

  p {
    margin: 0;
  }
}

.feedbackShade {
  margin-top: auto;
  padding: 20px;
  border-radius: 8px;
  
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}

.feedbackBox {
  padding: 13px 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  
  color: rgb(255, 255, 255);

  > .row1 {
    // text style
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    // line-height: 38px;
    text-align: left;

    margin-bottom: 32px;
  }

  > .row2 {
    display: flex;
    justify-content: space-between;

    font-family: Inter;
    font-size: 25px;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: -0.02em;
    text-align: left;

    margin-bottom: 12px;
    // color: rgba(255, 255, 255, 1);

    .userStarts {
      line-height: 5px;
    }
  }

  > .row3 {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .right {
      display: flex;
      gap: 30px;

            .arrow {
                padding: 12px;
                border-radius: 50%;
                line-height: 0;
                border: 1px solid rgba(255, 255, 255, 0.5);
                transition: background-color 0.5s ease;

                .white {
                    display: block;
                }

                .black {
                    display: none;
                }
            }
            
            .arrow:hover {
                background-color: rgba(255, 255, 255, 0.5);
                
                .white {
                    display: none;
                }
                
                .black {
                    display: block;
                }
            }
        }
    }
}

#signUp {
  width: 100%;
  padding: 0 64px;
}

.headerImg {
  width: 155px;
  height: 65px;
  // gap: 0px;
  // opacity: 0px;
}

.title {
  font-family: Inter;
  font-size: 26px;
  font-weight: 500;
  line-height: 38px;
  text-align: left;
  margin-top: 38px;
  padding: 0;
}

.sub-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color:#5E5E5E;
  margin-top: 12px;
}

.label-input {
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 24px 0;

  label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }
  .input-container {
    display: flex;
    align-items: center;
  }
  .toggle-icon {
    position: absolute;
    top: 37px;
    right: 0px;
    cursor: pointer;
    // width: 64px;
    margin-right: 13px;
    height: 24px;

    &.error {
      right: 24px;
    }

  }
  
  .attention {
    font-weight: 400 !important;
  }

  input {
    font-size: 14px;
    margin-top: 6px;
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid rgba(174, 174, 174, 1);
    outline: none;
  }

  input:focus {
    border-color: rgb(68, 8, 20);
    outline: 5px solid rgb(208, 194, 197);
  }

    button {
        border: 1px solid rgba(114, 13, 33, 1);
        background: rgba(114, 13, 33, 1);
        margin-top: 6px;
        padding: 10px 14px;
        border-radius: 8px;
        color: rgba(255, 255, 255, 1);
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        transition: background-color 0.4s ease;
    }
    button:hover{
        background-color: #101828;
        transform: scale(1);
    }
}
.phoneNumber{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.aTagElement {
  color: rgba(57, 7, 17, 1);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  // line-height: 20px;
  // text-align: left;
}
.aTagElement:hover{
    color: rgba(57, 7, 17, 1);
}
.aTagElement:focus{
  border-color: rgb(68, 8, 20);
  outline: 5px solid rgb(208, 194, 197);
}
.singUp-check{
    display: flex;
    gap: 8px;
    margin-top: 7px;
} 
.custom-checkbox {
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

.singUp-check p {
  font-size: 14px;
  font-weight: 400;
  color: #5E5E5E;
}
.singnUpScroll {
  overflow-y: scroll;
  height: calc(100vh - 321px);
}
.singnUpScroll::-webkit-scrollbar {
  display: none;
}
.phone-number-input {
  display: flex;
  align-items: center;
  border: 1px solid rgb(174, 174, 174);
  border-radius: 8px;
  overflow: hidden;
  margin-top: 6px;
  font-size: 16px !important;
  // width: fit-content;
}
.country-code select {
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  padding: 0px 25px 0px 12px !important;
  color: #4a4a4a;
  font-weight: 400;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 0;
}
.phone-number-input:focus-within {
  border-color: rgb(68, 8, 20);
  outline: 5px solid rgb(208, 194, 197);
}
.phone-number-input input {
  border: none;
  outline: none;
  font-size: 16px;
  padding: 10px;
  width: 100%;
}
.phone-number-input select,
.phone-number-input input {
  padding: 10px;
}

.phone-number-input::after {
  font-size: 12px;
  margin-left: -20px;
  pointer-events: none;
}
.country-code {
  position: relative;
}
.country-code::after {
  content: url('../../public/icons/hearAboutOption.svg');
  position: absolute;
  // left: 35px;
  right: 10px;
  top: 3px;
//   font-weight: 100 !important;
//   transform: translateY(-50%);
  pointer-events: none;
//   font-size: 12px;
//   color: #333;
}
.hearAbout {
  width: 200px;
  height: auto;
  position: absolute;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  z-index: 100;
  background-color: #ffffff;
}
.hearAboutus {
  width: 200px;
  height: auto;
  position: absolute;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  z-index: 100;
  background-color: #ffffff;
  top:-152px;
}
.options {
  display: flex;
  gap: 10px;
  padding: 0px 7px;
  cursor: pointer;
}
.options:hover{
  padding: 0px 7px;
  border-radius: 7px;
  background-color: #F0F0F0;
}
.options.focused {
  background-color: #F0F0F0;
  border-radius: 7px;
}
.options h3 {
  font-family: Inter;
  margin-bottom: 0px;
  font-size: 14px !important;
  font-weight: 500;
  line-height: 29px;
  color: #4a4a4a;
  cursor: pointer;
}
  .outlineInput{
   border-radius: 8px;
   outline: 2px solid #440814;
   height: 50px;
   width: 50px;
  }
  .codeInput input{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #AEAEAE;
    height: 50px;
    width: 50px;
    font-size: 30px;
    font-weight: 500;
    color:#440814 ;
    text-align: center;
    margin-top: 0px;
  }
  .hyphen{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 28px;
  }
  .hyphen h3{
    color: #AEAEAE;
    font-size: 55px;
    margin-top: 0px; 
  }
  .backsignUp{
    display: flex;
    justify-content: center;
    gap: 10px;
    // margin-top: 0px !important;
    text-decoration: none;
  }
  .backsignUp:focus{
    border-color: rgb(68, 8, 20);
    outline: 5px solid rgb(208, 194, 197);
  }
  .backsignUp p{
    text-align: center;
    font-size: 14px;
    font-weight:600;
    color: #5E5E5E;
  }
  .backsignUp img{
    margin-bottom: 12px;
    color: #5E5E5E;
  }
  .mssgeMainDiv{
    width: 56px;
    height: 56px;
    border-radius: 12px;
    border: 1px solid #CDCDCD;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mssgeMainDiv img{
    max-width: 100%;
    max-height: 100%;
  }
.selectBorderHearAboutUs{
  margin-top: 6px !important;
  // padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid rgb(174, 174, 174);
  outline: none;
  cursor: pointer;
}

.selectBorderHearAboutUs:focus-within{
  border-color: rgb(68, 8, 20) !important;
  outline: 5px solid rgb(208, 194, 197) !important;
}


.selectBorderHearAboutUs span{
  font-size: 14px;
  font-weight: 400;
  color:#5E5E5E
}
.hearAboutUsLeftCol{
  margin: 10px -33px 13px 15px !important;
}
.hearAboutUsRightCol{
  border: 1px solid rgb(174, 174, 174);
  border-style: none none none solid;
  align-items: center;
  padding: 0px 10px;
  position:relative;
}
.hearAboutUsRightCol img{
  height: 33px;
  width: 22px;
}
input[type="checkbox"] {
  accent-color: rgb(114, 13, 33);
  width: 16px;
  }
.labelFontSizeForhearAbotus{
    font-size: 15px !important;
    cursor: pointer;
  }
  .otherValueName {
    color: #720D21 !important;
    font-weight: bold !important;
    font-size: 15px !important;
}
  
.hearAboutusCustomInput:focus{
    outline:none !important;
  }
  .hearAboutUsToggleButton{
    display: flex;
    width: 100%;
    border-style: grey !important;
    border: 1px solid grey !important;
    background: transparent !important;
    color: black !important;
    font-size: 14px;
    justify-content: space-between;
  }
  .hearAboutUsToggleButton :focus{
    box-shadow: none !important;
  }
  .attention{
    color: #F04438;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }

  // .upperCasing {
  //   text-transform: capitalize !important;
  //   }
    .upperCasing::placeholder {
      text-transform: capitalize !important;
  }

  .star-container {
    display: flex;
    align-items: center;
  }
  
  .star-outer {
    position: relative;
    display: inline-block;
    width: 19px;
    height: 19px; 
    margin-right: 4px;
    clip-path: polygon(
      50% 0%,
      61% 35%,
      98% 35%,
      68% 57%,
      79% 91%,
      50% 70%,
      21% 91%,
      32% 57%,
      2% 35%,
      39% 35%
    );
    background-color: #ccc;
  }
  .star-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
    // clip-path: inherit;
  }
  #timer{
    font-weight: 500;
    font-size: 30px;
    color: #440814;
  }
  .csscolor{
    color:#720C20;
    font-weight: 500;
    transition: 0.2s ease-in-out all;
    font-size: 1.6rem;
  }
  .center_div_contant_one_second{
    display:grid;
    place-items: center;
    flex-grow: 1;
    transition: all 1s;
    
    
  }
  .center_div_contant_one_first{
    display:grid;
    place-items: center;
    // width: 100;
    transition: all 1s;
    position:relative;
    float: left;
    z-index: 99999;
    opacity:0.8;
    
  }
  .center_div_contant_two_second{
    display:grid;
    place-items: center;
    transition: all 2s;
    // width: 40%;
    // width: 0;
    opacity: 0.1;
  }
  .center_div_contant_two_first{
    display: grid;
    float: left;
    opacity: .8;
    place-items: center;
    position: relative;
    transition: all 1s;
    z-index: 99999;
  }  

  .universalHeaderOptions {
    img {
    min-width: 200px;

    }
  }
  .selectRole select{
    min-height: 1.563rem;
    width: 100%;
    // padding: 0.375rem 0.75rem !important;
    padding: 4px 10px;
    border-radius: 2px;
    border-radius: 2px;
    font-family: inherit;
    font-size: 17px;
    line-height: inherit;

    &:focus,
    &:focus-visible {
      outline: inherit;
      box-shadow: none;
    }
  }
  .selectRole h2{
    font-size: 27px;
    font-weight: 400;
    color: (5, 81, 96)
  }
  .selectRole p {
    font-size: 20px !important;
    font-weight: 400;
    color: (5, 81, 96)
  }
  .dropdown-item{
     font-family: Arial, sans-serif;
    border-radius: 8px;
    color: #000000 !important;
    font-weight: 400 !important;
    font-size: 14px;
    cursor: pointer;
  }
  .validation-icon {
    position: absolute;
    top: 41px;
    right: 10px;
    // cursor: pointer;
    width: 16px;
    height: 16px;
  }
  .input-error {
    border: 1px solid #FDA29B !important;
  }
  
  .toaster {
    position: fixed;
    top: 20px;
    right: 20px;
    align-items: center;
    background: linear-gradient(90deg, #FEF0C7 0%, #FFFFFF 100%);
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    padding: 12px;
    max-width: 400px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #5a5a5a;
    z-index: 1000;
  }

  .success-bg {
    background: linear-gradient(90deg, #DCFAE6 0%, #FFFFFF 100%) !important;
    color: rgba(94, 94, 94, 1) !important;
    .toaster-title {
      color: rgba(5, 51, 33, 1) !important;
    }
  }

  .toaster_T{
    display: flex;
    gap: 10px;
  }
  .toaster-icon {
    width: 28px;
    height: 28px;
    background-color: white;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px -4px rgb(16 24 40 / 39%);
  }
 .toaster-icon img{
    max-width: 100%;
    max-height: 100%;
 } 
  .toaster-title {
    margin-top: 3.5px;
    font-weight: 600;
    margin-bottom: 4px;
    color: #4E1D09;
    font-size: 14px;
    text-transform: capitalize;
  } 
  .toaster-close {
    cursor: pointer;
    margin-left: auto;
    font-size: 24px;
    font-weight: 400;
    color:  #4E1D09;
  }
  .toaster-description{
    font-family: Arial, sans-serif;
    color: #5E5E5E;
    font-weight: 400;
    font-size: 14px;
    margin: auto 37px;
  }
  .countDown{
    text-align:center;
    color: #440814;
    font-weight: 500;
  }
  .wentWrong img{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    opacity: 0.2;
  }
  .linkExpired img{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    opacity: 0.2;
  }
  
  #activation{
    width: 100%;
  }
  #unAuthorized{
    width: 100%;
  }

.loader-yellow {
  position: relative;

  .gif {
    width: 100%;
    max-width: 500px;
    margin: auto;

    img {
      width: 100%;
    }
  }

  .desc {
    position: absolute;
    top: 80%;
    width: 100%;
  }
}

// Login page css

#logIn,#sucessReset, #changePassword, #invalid, #Otp, #forgetPassword, #congratulation, #loaderScren, #signUp{
  width: 100%;
  padding: 0 30px;
}

#signUp,
#logIn , #changePassword, #invalid, #Otp, #forgetPassword, #congratulation, #selectRole, #loaderScren{
  .other-links {
    text-align: center;
    margin-top: 24px;

    p {
      margin-top: 16px;
    }
  }
}

#selectRole{
  width:35%;
  align-items: center;
  margin: 0px auto;
  font-family:'Inter';
  .title{
    color:#475467;
  }
  label{
    color:#475467;
  }
  .heading2{
    font-size:16px;
    font-weight:500;
    color:#040404;
  }
}



.roleOptions{
  display: flex;
  justify-content: space-between;
  width:100%;
  padding:15px;
  border:1px solid #E9E8E8;
  border-radius: 12px;
  margin-top:10px;
  color: #000000;
  cursor: pointer;

}

.roleOptions-active{
  background-color: #F0F0F0;
}

.active-tab{
  background-color: #F0F0F0;
  margin: 0px;
  font-size: 14px;
  padding:8px 15px;
  border:none;
  span{
    padding: 0px;
  }
}

.ActiveImg{
  position: relative;
}
.greenBtn{
  position: absolute;
  width:11px;
  height:11px;
  background-color: #12B76A;
  border-radius: 50%;
  left:30px;
  margin-top: -9px;
}

.invalidPage {
  display: grid;
  place-items: center;
  // height: 100%;
  // width: 100%;
  margin-top: -80px;
  min-height: 100%;
}

.vertically-center {
  margin-top: max(calc(50vh - 290px), 0vh);
}
.vertically-center2{
    margin-top: max(calc(calc(100vh - 370px) / 2), 0vh);
}
.vertically-center3 {
    margin-top: max(calc(calc(100vh - 450px) / 2), 0vh);
}
.vertically-center4 {
    margin-top: max(calc(calc(100vh - 470px) / 2), 0vh);
}
.vertically-center5 {
    // margin-top: max(calc(calc(100vh - 762px) / 2), 0vh);
    margin-top: max(calc(calc(100vh - 620px) / 2), 0vh);
}
.vertically-center6 {
    margin-top: max(calc(calc(100vh - 450px) / 2), 0vh);
}
.vertically-center7 {
    margin-top: max(calc(calc(100vh - 530px) / 2), 0vh);
}
.vertically-center9 {
  margin-top: max(calc(calc(100vh - 395px) / 2), 0vh);
}
// .vertically-center8 {
//   margin-top: max(calc(calc(100vh - 600px) / 2), 0vh);
// }
.vertically-center10 {
  margin-top: max(calc(calc(100vh - 800px) / 2), 0vh);
}
.vertically-center11 {
  margin-top: max(calc(calc(100vh - 590px) / 2), 0vh);
}
.vertically-center12 {
  margin-top: max(calc(calc(100vh - 550px) / 2), 0vh);
}


// =========== All media queries goes here =======================
@media screen and (min-width: 1441px) and (max-width: 2560px){
// @media (min-width:2560px){
  .label-input label {
    font-size: 21px;
  }
  #logIn,#sucessReset, #changePassword, #invalid, #Otp, #forgetPassword, #congratulation, #selectRole, #loaderScren, #signUp, #unAuthorized{
    padding: 0 30px;
  }
  .label-input label{
    font-size: 14px;
  }
  .phoneNumber{
    font-size: 14px;
  }
  // .singUp-check p{
  //   font-size: 17px;
  // }
  .title{
    font-size: 30px;
  }
  .sub-title{
    font-size: 16px;
  }
  .backsignUp p {
    font-size: 17px;
  }
  .feedbackBox > .row1 {
    font-size: 30px;
  }
  .left_P{
   font-size: 22px;
  }
  .custRole{
    display: none;
   }
}
@media (max-width:1440px){
  .label-input label{
    font-size: 14px;
  }
   .custRole{
    display: none;
   }
  
}

@media (max-width: 1024px) {
  .feedbackBox > div {
    flex-direction: column;
    text-align: center !important;
    
    div {
      padding: 10px 0;
      justify-content: space-around;
    }
  }
  .center_div_contant_one_first{
    display: none;
  }
  .label-input label{
    font-size: 14px;
  }
   .left_P{
    display: none;
   }
   .custRole{
    display: block;
   }
   #logIn,#sucessReset, #changePassword, #invalid, #Otp, #forgetPassword, #congratulation, #selectRole, #loaderScren, #signUp, #unAuthorized, #activation{
    padding: 0 20px;
  }
    

}
@media screen and (min-width: 992px) and (max-width: 1023px) {

  html,
  body {
    font-size: 11px;
  }
  .login-one img{ 
    width: 100% ;
    height: 100vh;
   
 }
 .left_P{
  display: none;
 }
}
@media(max-width:768px){
  #signUp, #logIn, #forgetPassword, #changePassword, #invalid, #Otp, #congratulation, #sucessReset, #unAuthorized, #activation {
    padding: 0px 3px;
  } 
  .codeInput input{
    width: 38px;
    height: 38px;
    font-size: 20px;
    padding: 0px;
  }
  .outlineInput{
    width: 38px;
    height: 38px;
  }
  .hyphen{
    height: 38px;
    width: 38px;
  }  
  .center_div_contant_two_first{
    position: absolute;
  }
  .row1 p{
    font-size: 16px;
  }
  .row2 p{
    font-size: 20px;
  }
  .title{
    font-size: 24px;
  }
  .label-input label{
    font-size: 14px;
  }
  .feedbackBox > .row1{
    margin-bottom: 8px;
  }
  .feedbackBox > div {
    text-align: center !important;
    
    div {
      padding: 8px 0px;
      justify-content: center;
    }
  }
  .right{
  margin-top: 0px;
  // padding-top: 0px;
  }
  .userStarts{
    padding: 0px !important;
  }
  .left{
    padding: 0px !important;
  }
  .custRole{
    font-weight: 400;
    font-size: 14px;
    padding-top: 5px;
  }
  .left_P{
    display: none !important;
  }
  .feedbackBox{
    padding: 5px 5px;
  }
 
}
@media(max-width:320px){
  .codeInput input{
    width: 35px;
    height: 35px;
  }
  .outlineInput{
    width: 35px;
    height: 35px;
  }
  .hyphen{
    height: 35px;
    width: 35px;
  }  
    .left {
width: 100% !important;
  }
}

@media (max-width: 660px) {
  #mainBody > .right {
    display: none;
  }
  .left {
    width: 100% !important;
      }
}
//loader
.overLayLoader{
  width: 100vw;
  height: auto;
  min-height: 100vh;
  height:100%;
  background-color: rgb(0, 0, 0, 0.2);
  z-index: 10000;
}

.overLayLoader::after{
  content: "Processing...";
  font-size: 20px;
  margin-left: 10px;
  color: #720C20;
}
.selectedRole{
  color: #000000 !important;
}
.selected-item{
  color: #000000 !important;
}

#idleTimerModal{
  &.modal-dialog.slide-down {
    animation: slideDown 4s ease-out;
  }
  &.modal-dialog{
    max-width: none !important;
    width: 612px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    position: relative;
    transition: transform .3s ease-out;
  }

  @keyframes slideDown {
    from {
      transform: translateY(-100vh);
    }
    to {
      transform: translateY(0);
    }
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }
  .fade {
    transition: opacity .15s linear;
  }
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 10px;
    outline: 0;
  }
  .modal-header{
    background-color: #FFFFFF;
    margin: 15px;
    margin-bottom: 0px;
    flex-shrink: 0;
    align-items: center;
    padding: 1rem 1rem;
  }
  .modal-body{
    margin: 15px;
    margin-top: 0px;
    font-family: Inter;
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }
  .btn-close{
    color: #939393;
    font-size: 14px;
    font-weight: 600;
  }
  .idleTimerClock{
    width: 120px;
    height: 120px;
  }
  .idleTimerContent h1{
    font-size: 18px;
    font-weight: 700;
    color: #1A1A1A;
    padding: 10px;
  }
  .idleTimerContent p{
    font-size: 15px;
    font-weight: 400;
    color: #606060;
    padding: 10px;
  }
  .idleTimerSession h3{
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    padding: 10px;
  }
  .idleTimerSession h2{
    font-size: 20px;
    font-weight: 700;
    color: #720C20;
    padding: 10px;
  }
  .idleTimerButton button {
    border: 1px solid #d9dee6;
    background-color: #ffffff;
    font-size: 15px;
    font-weight: 400;
    color: #000000;
    padding: 12px 56px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s, color 0.3s;
  }
  .idleTimerButton button.active,
  .idleTimerButton button:hover {
      border: 0px;
      background-color: #720c20;
      font-size: 15px;
      font-weight: 400;
      color: #ffffff;
      padding: 12px 56px;
      border-radius: 8px;
  }
  .idleTimerButton .row {
      padding-top: 15px;
  }
}

//-----------------------------------Banner Css-----------------------------------

.banner_P{
  width: 100%;
  height: 74px;
  background-color: #CE9C4A;
  border-radius: 7px;
}
.banner_C{
  display: flex;
  gap: 14px;
  padding-top: 13.4px;
}
.banner_C h5{
  line-height: 24px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
}
.banner_C p{
  line-height: 24px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
}
.ringingBell{
  width: 48px;
  height: 48px;
  background-color: #FFFFFF;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ringingBell img{
  max-width: 100%;
  max-height: 100%;
}
@keyframes vibrate {
  0% { transform: translateX(0); }
  20% { transform: translateX(-2px); }
  40% { transform: translateX(2px); }
  60% { transform: translateX(-2px); }
  80% { transform: translateX(2px); }
  100% { transform: translateX(0); }
}
.ringingBell img:hover{
  cursor: pointer;
  animation: vibrate 0.3s linear infinite;
}
.upperCase{
  text-transform: uppercase !important;
}
.parentDiv{
  max-width: 448px;
  // margin: auto;
}

// --------Button Focus ---------------

.singUpBtn:focus{
  border-color: rgb(68, 8, 20);
  outline: 5px solid rgb(208, 194, 197);
}
.verifyBtn:focus{
  border-color: rgb(68, 8, 20);
  outline: 5px solid rgb(208, 194, 197);
}
.setResetBtn:focus{
  border-color: rgb(68, 8, 20);
  outline: 5px solid rgb(208, 194, 197);
}